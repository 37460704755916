<template>
  <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x :disabled="disabled">
    <template v-slot:activator="{ on, attrs }">
      <v-btn depressed color="success" :disabled="disabled" v-bind="attrs" v-on="on" class="flex-grow-1">
        <font-awesome-icon icon="play" pull="left"></font-awesome-icon>
        {{ btntext }}
      </v-btn>
    </template>
    <v-form v-model="valid" ref="createSessionForm" lazy-validation @submit.prevent="doaction()">
      <v-card class="pa-4">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Enter Session Name</v-list-item-title>
              <v-list-item-subtitle>A friendly name for you to identify later in reports</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list>
          <v-list-item>
            <v-text-field v-model="sessionname" label="Session Name" :rules="sessionnamereule"></v-text-field>
          </v-list-item>
          <v-list-item>
            <v-textarea
              v-if="quiztype !== 'L'"
              v-model="emaillist"
              label="Invite By Email"
              :rules="emaillistrule"
              placeholder="Use commas to enter multiple emails"
            ></v-textarea>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-btn large color="success" :disabled="!valid" @click="doaction">
            <font-awesome-icon icon="plus" pull="left"></font-awesome-icon>
            Create Session
          </v-btn>
          <v-btn large text @click="menu = false">
            <font-awesome-icon icon="times" pull="left"></font-awesome-icon>
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-menu>
</template>

<script>
export default {
  name: "PopoverMenu",
  props: {
    // quiz: {
    //   type: Object,
    //   default: () => null,
    // },
    quiztype: {
      type: String,
      default: () => null,
    },
    // quizid: {
    //   type: String,
    //   default: () => null,
    // },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    fav: true,
    menu: false,
    message: false,
    hints: true,
    valid: true,
    emaillist: "",
    sessionname: "",
    sessionnamereule: [(v) => !!v || "Session Name is required!"],
    emaillistrule: [
      (v) => !!v || "Email List is required!",
      (v) => {
        var emails = v.replace(/\s/g, "").split(",")
        var regex =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        var valid = []
        console.log(emails)

        for (let i = 0; i < emails.length; i++) {
          if (emails[i] == "" || !regex.test(emails[i])) {
            valid.push(false)
          } else {
            valid.push(true)
          }
        }
        return valid.every((e) => e === true) ? !!v : "Email Badly Formatted"
      },
    ],
  }),

  computed: {
    btntext() {
      return this.disabled ? "Add 5 Qs" : "Start"
    },
  },
  methods: {
    doaction() {
      this.$emit("createsession", this.sessionname, this.emaillist)
      this.menu = false
    },
  },
}
</script>

<style lang="scss" scoped></style>
