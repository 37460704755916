var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": false,
        "nudge-width": 200,
        "offset-x": "",
        disabled: _vm.disabled
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "flex-grow-1",
                      attrs: {
                        depressed: "",
                        color: "success",
                        disabled: _vm.disabled
                      }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "play", pull: "left" }
                  }),
                  _vm._v(" " + _vm._s(_vm.btntext) + " ")
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.menu,
        callback: function($$v) {
          _vm.menu = $$v
        },
        expression: "menu"
      }
    },
    [
      _c(
        "v-form",
        {
          ref: "createSessionForm",
          attrs: { "lazy-validation": "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.doaction()
            }
          },
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-4" },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v("Enter Session Name")
                          ]),
                          _c("v-list-item-subtitle", [
                            _vm._v(
                              "A friendly name for you to identify later in reports"
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Session Name",
                          rules: _vm.sessionnamereule
                        },
                        model: {
                          value: _vm.sessionname,
                          callback: function($$v) {
                            _vm.sessionname = $$v
                          },
                          expression: "sessionname"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    [
                      _vm.quiztype !== "L"
                        ? _c("v-textarea", {
                            attrs: {
                              label: "Invite By Email",
                              rules: _vm.emaillistrule,
                              placeholder: "Use commas to enter multiple emails"
                            },
                            model: {
                              value: _vm.emaillist,
                              callback: function($$v) {
                                _vm.emaillist = $$v
                              },
                              expression: "emaillist"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        large: "",
                        color: "success",
                        disabled: !_vm.valid
                      },
                      on: { click: _vm.doaction }
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "plus", pull: "left" }
                      }),
                      _vm._v(" Create Session ")
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { large: "", text: "" },
                      on: {
                        click: function($event) {
                          _vm.menu = false
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "times", pull: "left" }
                      }),
                      _vm._v(" Cancel ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }